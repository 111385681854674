.all-projects-container {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: 10px;
	width: 100%;
	align-items: stretch;
	padding-top: 5px;
	padding-bottom: 5px;
	margin-left: -35px;
	margin-right: -35px;
}

.all-projects-project {
	width: calc(100% / 3);
	box-sizing: border-box;
	padding: 5px;
	overflow: hidden;
	width: 100%;
}

@media (max-width: 800px) {
	.all-projects-project {
		width: 100%;
	}
}
